export const permissionLevels = {
  organization: [
    "ORGANIZATION_OWNER",
    "ORGANIZATION_ADMIN",
    "ORGANIZATION_MEMBER"
  ],
  project: [
    "PROJECT_ADMIN",
    "PROJECT_EDITOR",
    "PROJECT_VIEWER"
  ]
} //Lower index for higher permissions level

export const permissionLevelsRequired = {
  organizationDelete: 'ORGANIZATION_OWNER',
  organizationUserInvite: "ORGANIZATION_ADMIN",
  organizationUserRemove: "ORGANIZATION_ADMIN",
  organizationUserAdd: "ORGANIZATION_ADMIN",
  organizationUserRoleUpdate: "ORGANIZATION_ADMIN",
  organizationEdit: "ORGANIZATION_ADMIN",
  organizationProjectsView: "ORGANIZATION_MEMBER",
  organizationCreditsAdd: "ORGANIZATION_OWNER",
  organizationExpensesListView: "ORGANIZATION_OWNER",
  organizationTransactionHistoryView: "ORGANIZATION_ADMIN",

  projectUserAdd: "PROJECT_ADMIN",
  projectUserInvite: "PROJECT_ADMIN",
  projectUserDelete: "PROJECT_ADMIN",
  projectEdit: "PROJECT_ADMIN",
  projectAdd: "ORGANIZATION_ADMIN",
  projectView: "ORGANIZATION_MEMBER",
  projectDelete: "PROJECT_ADMIN",
  projectSamplesView: "PROJECT_VIEWER",
  projectAnalysesView: "PROJECT_VIEWER",
  projectPaidAction: "PROJECT_EDITOR",

  sampleAdd: "PROJECT_EDITOR",
  sampleProlong: "PROJECT_EDITOR",
  sampleDelete: "PROJECT_EDITOR",
  sampleView: "PROJECT_VIEWER",
  sampleFilesView: "PROJECT_EDITOR",
  sampleEdit: "PROJECT_EDITOR",
  samplePropertiesChange: "PROJECT_EDITOR",

  analysisAdd: "PROJECT_EDITOR",
  analysisView: "PROJECT_VIEWER",
  analysisFilesView: "PROJECT_VIEWER",
  analysisEdit: "PROJECT_EDITOR",
  analysisCancel: "PROJECT_EDITOR",

  fileUploadView: "PROJECT_EDITOR",
  fileUploadCancel: "PROJECT_EDITOR", //Problem - to nie dzieje sie w widoku projektu ani zadnym widoku z ustalonym projektem
  fileDelete: "PROJECT_EDITOR",
  fileUpload: "PROJECT_EDITOR",

  useVariantMiner: "PROJECT_EDITOR",
  variantRepRead: "PROJECT_VIEWER"

};