<template>
  <div>
    <v-iseq-card
      :title="`Variant REP - ${repname} ${variantRepFile.main ? '' : '(Historical version)'}`"
      class="iseq-variant-rep"
    >
      <v-data-table
        :headers="tableColumns"
        :items="variantRepFile.variants"
      >
        <template #[`item.submitter`]="{ item }">
          {{ item.submitter.email }}
        </template>

        <template #[`item.primaryClassification`]="{ item }">
          {{ translateClassificationName(item.primaryClassification) }}
        </template>

        <template #[`item.manualClassification`]="{ item }">
          {{ translateClassificationName(item.manualClassification) }}
        </template>

        <template
          v-if="permissionRequired('useVariantMiner')"
          #[`item.actions`]="{ item }"
        >
          <v-iseq-button
            type="secondary"
            small
            style="margin-right: 5px"
            @click="reclassifyVariant(item)"
          >
            Reclassify
          </v-iseq-button>

          <v-iseq-button
            type="red"
            small
            @click="removeVariant(item)"
          >
            Remove variant
          </v-iseq-button>
        </template>
      </v-data-table>
    </v-iseq-card>

    <iseq-confirmation-modal
      v-if="removalModalVisibility"
      width="600px"
      header="Remove variant reclassification"
      :message="`Do you want to remove manual reclassification of this variant? (${variantToRemove.gene})`"
      confirm-button-type="danger"
      confirm-button-text="Remove variant"
      reject-button-type="secondary"
      reject-button-text="Close"
      @actionRejected="removalModalVisibility = false"
      @actionConfirmed="confirmRemoveVariant"
    />

    <iseq-variant-reclassification-modal
      v-if="reclassificationModalVisibility"
      :variant="variantToReclassify"
      :type="variantRepFile.type"
      @actionRejected="reclassificationModalVisibility = false"
      @actionConfirmed="confirmReclassifyVariant"
    />
  </div>
</template>

<script>
  import IseqCard from "@/components/ui/IseqCard.vue";
  import {mapState} from "vuex";
  import IseqButton from "@/components/ui/IseqButton.vue";
  import IseqConfirmationModal from "@/components/modals/IseqConfirmationModal.vue";
  import IseqVariantReclassificationModal from "@/components/modals/IseqVariantReclassificationModal.vue";
  import {permissionCheck} from "@/plugins/permissions";

  export default {
    name: "VariantRepFile",
    components: {
      "v-iseq-button": IseqButton,
      "v-iseq-card": IseqCard,
      IseqConfirmationModal,
      IseqVariantReclassificationModal
    },
    data: function () {
      return {
        uuid: "",
        variantToRemove: undefined,
        variantToReclassify: undefined,
        removalModalVisibility: false,
        reclassificationModalVisibility: false,
        variantNewClassification: undefined,
        classificationsNames: [
          {id: "BENIGN", name: "Benign"},
          {id: "LIKELY_BENIGN", name: "Likely Benign"},
          {id: "VUS", name: "VUS"},
          {id: "LIKELY_PATHOGENIC", name: "Likely Pathogenic"},
          {id: "PATHOGENIC", name: "Pathogenic"},
          {id: "TIER_IA", name: "Tier Ia"},
          {id: "TIER_IB", name: "Tier Ib"},
          {id: "TIER_IIC", name: "Tier IIc"},
          {id: "TIER_IID", name: "Tier IId"},
          {id: "TIER_III", name: "Tier III"},
          {id: "TIER_IV", name: "Tier IV"}
        ]
      }
    },
    computed: {
      ...mapState('project', ['project']),
      ...mapState('analysis', ['variantRepFile']),
      repname() {
        if (this.variantRepFile.type) {
          return this.variantRepFile.type === 'SOMATIC' ? 'Somatic variants' : 'Germline variants';
        } else {
          return "";
        }
      },
      tableColumns() {
        let columns = [
          {text: 'Gene', value: 'gene'},
          {text: 'Variant', value: 'variant'},
          {text: 'Tumor type', value: 'tumorType'},
          {text: 'Tumor subtype', value: 'tumorSubtype'},
          {text: 'Primary classification', value: 'primaryClassification'},
          {text: 'Manual classification', value: 'manualClassification'},
          {text: 'Submitter', value: 'submitter'},
          {text: 'Submission date', value: 'date'},
          {text: 'Comment', value: 'comment'}
        ]

        if (this.variantRepFile.main) {
          columns.push({text: 'Actions', value: 'actions'})
        }

        return columns;
      }
    },
    watch: {
      project: {
        deep: true,
        handler() {
          this.$router.push({path: '/variant-rep'});
        }
      }
    },
    created() {
      this.uuid = this.$route.params.uuid;
      this.$store.dispatch("analysis/getVariantRepFile", {uuid: this.uuid});
    },
    methods: {
      permissionRequired(action) {
        return permissionCheck(action)
      },
      removeVariant(item) {
        this.variantToRemove = item;
        this.removalModalVisibility = true;
      },
      reclassifyVariant(item) {
        this.variantToReclassify = item;
        this.reclassificationModalVisibility = true;
      },
      confirmRemoveVariant() {
        this.removalModalVisibility = false;
        this.$store.dispatch("analysis/deleteVariant", {repUuid: this.variantRepFile.uuid, variantUuid: this.variantToRemove.uuid});
      },
      confirmReclassifyVariant(item) {
        this.reclassificationModalVisibility = false;
        this.$store.dispatch("project/reclassifyVariants", {uuid: this.project.uuid, data: {variants: [item], type: this.variantRepFile.type}, reroute: true});
      },
      translateClassificationName(id) {
        return this.classificationsNames.find(n => n.id === id).name;
      }
    },
  }
</script>

<style scoped lang="scss">
.iseq-variant-rep {

}
</style>