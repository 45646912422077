import Vue from 'vue'
import VueRouter from 'vue-router'
import VueAnalytics from 'vue-analytics'

import Dashboard from '@/views/Dashboard/Dashboard.view.vue'
import Login from '@/views/Login/Login.view.vue'
import Signup from '@/views/Login/Signup.view.vue'
import PasswordRetrieval from '@/views/Login/PasswordRetrieval.view.vue'
import store from '@/store'

import FAQ from '@/views/static/FAQ.view.vue'
import PrivacyPolicy from '@/views/static/PrivacyPolicy.view.vue'
import TermsOfUse from '@/views/static/TermsOfUse.view.vue'

import OrganizationList from "@/views/Organization/OrganizationList.view.vue";
import OrganizationDetails from "@/views/Organization/OrganizationDetails.view.vue";

import ProjectList from '@/views/Project/ProjectList.view.vue'
import ProjectDetails from "@/views/Project/ProjectDetails.view.vue";
import ProjectCreate from "@/views/Project/ProjectCreate.view.vue";

import SampleList from '@/views/Sample/SampleList.view.vue'
import SampleDetails from '@/views/Sample/SampleDetails.view.vue'
import SampleCreate from "@/views/Sample/SampleCreate.view.vue";

import AnalysisList from "@/views/Analysis/AnalysisList.view.vue";
import AnalysisDemoList from "@/views/Analysis/AnalysisDemoList.view.vue";
import AnalysisDemoDetails from "@/views/Analysis/AnalysisDemoDetails.view.vue";
import AnalysisDetails from "@/views/Analysis/AnalysisDetails.view.vue";
import AnalysisCreate from '@/views/Analysis/AnalysisCreate.view.vue'

import Profile from "@/views/Profile/Profile.view.vue";

import WorkflowList    from "@/views/Workflow/WorkflowList.view.vue";
import WorkflowDetails from "@/views/Workflow/WorkflowDetails.view.vue";
import { Auth }        from 'aws-amplify';
import {getDocumentTitle} from "@/plugins/utils";
import CookiesConsents from "@/views/static/CookiesConsents.vue";
import BugReportingView from "@/views/static/BugReporting.view.vue";
import VariantMinerView from "@/views/VariantMiner/VariantMiner.view.vue";
import VariantRepView from "@/views/VariantMiner/VariantRep.view.vue";
import VariantRepFileView from "@/views/VariantMiner/VariantRepFile.view.vue";
import VariantRepHistoryView from "@/views/VariantMiner/VariantRepHistory.view.vue";

Vue.use(VueRouter);

const routes = [
  { path: '/', name: 'home', component: Dashboard, meta: { requiresAuth: true, group: 'Dashboard', title: "Dashboard" } },
  { path: '/login', name: 'login', component: Login, meta: { requiresAuth: false, group: 'Dashboard', title: "Login"  } },
  { path: '/sign-up', name: 'signup', component: Signup, meta: { requiresAuth: false, group: 'Dashboard', title: "Sign Up"  } },
  { path: '/forgot-password', name: 'forgot-password', component: PasswordRetrieval, meta: { requiresAuth: false, group: 'Dashboard', title: "Forgot Password"  } },
  { path: '/logout', name: 'logout', component: Login, meta: { requiresAuth: false, group: 'Dashboard', title: "Logout"  } },

  { path: '/FAQ', name: 'FAQ', component: FAQ, meta: { requiresAuth: false, group: 'FAQ', title: "FAQ" } },

  { path: '/privacy-policy', name: 'privacy-policy', component: PrivacyPolicy, meta: { requiresAuth: false, title: "Privacy Policy" } },

  { path: '/terms-of-use', name: 'terms-of-use', component: TermsOfUse, meta: { requiresAuth: false, title: "Terms of Use" } },

  { path: '/cookies-consents', name: 'cookies-consents', component: CookiesConsents, meta: { requiresAuth: false, title: "Cookies consents" } },

  { path: '/report-problem', name: 'problem-report', component: BugReportingView, meta: { requiresAuth: true, title: "Report a problem" } },

  { path: '/organizations', name: 'organization-list', component: OrganizationList, meta: { requiresAuth: true, group: 'Organizations', title: "Organizations" } },
  { path: '/organizations/:uuid', name: 'organization-details', component: OrganizationDetails, meta: { requiresAuth: true, group: 'Organizations', title: "Organizations" } },

  { path: '/projects', name: 'project-list', component: ProjectList, meta: { requiresAuth: true, group: 'Projects', title: "Projects" } },
  { path: '/projects/new', name: 'project-create', component: ProjectCreate, meta: { requiresAuth: true, group: 'Projects', title: "Projects" } },
  { path: '/projects/:uuid', name: 'project-details', component: ProjectDetails, meta: { requiresAuth: true, group: 'Projects', title: "Projects" } },

  { path: '/samples', name: 'sample-list', component: SampleList, meta: { requiresAuth: true, group: 'Samples', title: "Samples" } },
  { path: '/samples/new', name: 'sample-create', component: SampleCreate, meta: { requiresAuth: true, group: 'Samples', title: "Samples" } },
  { path: '/samples/:uuid', name: 'sample-details', component: SampleDetails, meta: { requiresAuth: true, group: 'Samples', title: "Samples" } },

  { path: '/analyses', name: 'analysis-list', component: AnalysisList, meta: { requiresAuth: true, group: 'Analyses', title: "Analyses" } },
  { path: '/analyses/demo', name: 'analysis-demo-list', component: AnalysisDemoList, meta: { requiresAuth: true, group: 'Analyses', title: "Analyses" } },
  { path: '/analyses/demo/:uuid', name: 'analysis-demo-details', component: AnalysisDemoDetails, meta: { requiresAuth: true, group: 'Analyses', title: "Analyses" } },
  { path: '/analyses/new', name: 'analysis-create', component: AnalysisCreate, meta: { requiresAuth: true, group: 'Analyses', title: "Analyses" } },
  { path: '/analyses/new/:name', name: 'analysis-create-for-workflow', component: AnalysisCreate, meta: { requiresAuth: true, group: 'Analyses', title: "Analyses" } },
  { path: '/analyses/:uuid', name: 'analysis-details', component: AnalysisDetails, meta: { requiresAuth: true, group: 'Analyses', title: "Analyses" } },

  { path: '/variant-miner', name: 'variant-miner', component: VariantMinerView, meta: { requiresAuth: true, group: 'VariantMiner', title: 'Variant MINER'} },
  { path: '/variant-rep', name: 'variant-rep', component: VariantRepView, meta: { requiresAuth: true, group: 'VariantRep', title: "Variant REP" } },
  { path: '/variant-rep/:uuid', name: 'variant-rep-file', component: VariantRepFileView, meta: { requiresAuth: true, group: 'VariantRep', title: "Variant REP file" } },
  { path: '/variant-rep/history/:type', name: 'variant-rep-history', component: VariantRepHistoryView, meta: { requiresAuth: true, group: 'VariantRep', title: "Variant REP History" } },

  { path: '/profile', name: 'profile', component: Profile, meta: { requiresAuth: true, group: 'Profile', title: "Profile" } },

  { path: '/workflows', name: 'workflow-list', component: WorkflowList, meta: { requiresAuth: true, group: 'Workflows', title: "Workflows" } },
  { path: '/workflows/:workflowId', name: 'workflow-details', component: WorkflowDetails, meta: { requiresAuth: true, group: 'Workflows', title: "Workflows" } }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeResolve(async (to, from, next) => {
  try {
    await Vue.prototype.$Amplify.Auth.currentAuthenticatedUser();
    const session = await Auth.currentSession();
    store.commit('security/updateToken', session.idToken.jwtToken);
    store.dispatch('notification/getNotifications');
    store.dispatch('invitation/getInvitations');
    document.title = getDocumentTitle(to.meta.title, store.getters["invitation/invitationsNumber"] + store.getters["notification/unreadNotificationsNumber"]);
    next();
  } catch (e) {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      store.commit('user/cleanAllData');
      next({path: "/login", query: {redirect: to.fullPath}})
    } else {
      next();
    }
  }
  if (String(to.path) === "/login" || String(to.path) === "/sign-up" || String(to.path) === "/forgot-password") {
    try {
      await Vue.prototype.$Amplify.Auth.currentAuthenticatedUser();
      next({path: "/"});
    } catch {
      next();
    }
  }
  next();
});

export default router

Vue.use(VueAnalytics, {
  id: 'UA-125435882-1',
  router
});