<template>
  <div class="iseq-report-variants-columns">
    <div class="iseq-report-variants-columns__content">
      <div class="iseq-report-variants-columns__content__table">
        <div class="iseq-report-variants-columns__content__table__selection">
          <div
            v-for="header in headersSelectionSearch"
            :key="header.value"
            class="iseq-report-variants-columns__content__table__selection__box"
          >
            <v-checkbox
              v-model="selectedHeaders"
              :value="header.value"
              hide-details
              color="primary"
            >
              <template #label>
                <span class="iseq-report-variants-columns__content__table__selection__box__label">{{ header.name ? header.name : header.text }}</span>
              </template>
            </v-checkbox>

            <div class="iseq-report-variants-columns__content__table__selection__box__separator" />

            <span class="iseq-report-variants-columns__content__table__selection__box__description">
              {{ header.description }}
            </span>

            <template v-if="header.display && (header.display === 'pill' || header.display === 'icon')">
              <div class="iseq-report-variants-columns__content__table__selection__box__separator" />

              <span class="iseq-report-variants-columns__content__table__selection__box__description">
                When downloaded data from this column will have following values:<br>
                <span
                  v-for="[key, value] of Object.entries(header.icons)"
                  :key="key"
                >
                  <span
                    v-if="header.display === 'pill'"
                    class="pill pill--inline"
                    :style="{'background-color':header.colors[key] ? header.colors[key] : 'black'}"
                  >
                    {{ value }}
                  </span>
                  <span v-else>
                    <v-icon color="text">
                      {{ value }}
                    </v-icon>
                  </span>

                  - {{ key }}<br>
                </span>
              </span>
            </template>
          </div>
        </div>
      </div>

      <div class="iseq-report-variants-columns__content__tools">
        <div class="iseq-report-variants-columns__content__tools__column">
          <div class="iseq-report-variants-columns__content__tools__title">
            Columns' settings
          </div>

          <div class="iseq-report-variants-columns__content__tools__searchbar">
            <v-text-field
              v-model="headerSearch"
              label="Search"
              outlined
              hide-details
            />
          </div>

          <div class="iseq-report-variants-columns__content__tools__button-row">
            <v-iseq-btn
              class="iseq-button"
              @click="selectAllColumns()"
            >
              Select all columns
            </v-iseq-btn>

            <v-iseq-btn
              class="iseq-button"
              @click="deselectAllColumns()"
            >
              Deselect all columns
            </v-iseq-btn>
          </div>

          <div class="iseq-report-variants-columns__content__tools__button-row">
            <v-iseq-btn
              class="iseq-button iseq-button--wide"
              @click="selectRecommendedColumns()"
            >
              Select recommended columns only
            </v-iseq-btn>
          </div>
        </div>

        <div class="iseq-report-variants-columns__content__tools__separator" />

        <div class="iseq-report-variants-columns__content__tools__column">
          <div class="iseq-report-variants-columns__content__tools__title">
            Download data
          </div>

          <div class="iseq-report-variants-columns__content__tools__button-row">
            <v-tooltip
              top
              :disabled="!!selectedHeaders.length"
            >
              <template #activator="{ on }">
                <div
                  class="iseq-button iseq-button--wide"
                  v-on="on"
                >
                  <v-iseq-btn
                    class="iseq-button iseq-button--wide"
                    :disabled="selectedHeaders.length < 1"
                    @click="(downloadColumnsData({all: false}))"
                  >
                    Download selected columns data to CSV file
                  </v-iseq-btn>
                </div>
              </template>

              <span>At least 1 column must remain selected</span>
            </v-tooltip>
          </div>

          <div class="iseq-report-variants-columns__content__tools__button-row">
            <v-tooltip
              top
              :disabled="!!selectedHeaders.length"
            >
              <template #activator="{ on }">
                <div
                  class="iseq-button iseq-button--wide"
                  v-on="on"
                >
                  <v-iseq-btn
                    class="iseq-button iseq-button--wide"
                    :disabled="selectedHeaders.length < 1"
                    @click="(downloadColumnsData({all: true}))"
                  >
                    Download all columns data to CSV file
                  </v-iseq-btn>
                </div>
              </template>

              <span>At least 1 column must remain selected</span>
            </v-tooltip>
          </div>
        </div>
      </div>
    </div>

    <div class="iseq-report-variants-columns__action-button">
      <v-tooltip
        top
        :disabled="!!selectedHeaders.length"
      >
        <template #activator="{ on }">
          <div style="width:100%; display: flex; justify-content: flex-end;" v-on="on">
            <v-iseq-btn
              class="iseq-button"
              style="margin-right: 0; margin-left: auto"
              :disabled="selectedHeaders.length < 1"
              @click="$emit('closed')"
            >
              Confirm
            </v-iseq-btn>
          </div>
        </template>

        <span>At least 1 column must remain selected</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
  import IseqButton from "@/components/ui/IseqButton.vue";

  export default {
    name: "IseqReportVariantsColumns",
    components: {
      "v-iseq-btn": IseqButton
    },
    props: {
      allHeaders: {
        type: Array,
        required: true
      },
      parentSelectedHeaders: {
        type: Array,
        required: true
      },
      previousAnalysisName: {
        type: String,
        required: true
      }
    },
    data: function () {
      return {
        headerSearch: "",
        selectedHeaders: []
      }
    },
    computed: {
      headersSelectionSearch() {
        if (!this.headerSearch) {
          return this.allHeaders;
        } else {
          return this.allHeaders.filter(header => header.name.toLowerCase().includes(this.headerSearch.toLowerCase()) || header.description.toLowerCase().includes(this.headerSearch.toLowerCase()));
        }
      }
    },
    watch: {
      parentSelectedHeaders: {
        handler(newValue) {
          this.selectedHeaders = newValue;
        },
        deep: true
      },
      selectedHeaders: {
        handler(newValue) {
          this.$emit("updateSelectedHeaders", newValue);
        },
        deep: true
      },
    },
    methods: {
      selectAllColumns() {
        this.deselectAllColumns();
        for (let header of this.allHeaders) {
          this.selectedHeaders.push(header.value);
        }
      },
      deselectAllColumns() {
        this.selectedHeaders = [];
      },
      selectRecommendedColumns() {
        this.deselectAllColumns();
        for (let header of this.allHeaders) {
          if (header.default) {
            this.selectedHeaders.push(header.value);
          }
        }
      },
      downloadColumnsData(payload) {
        const fieldnames = "id,name,description\r\n";
        let headersToDownload = payload.all
          ? this.allHeaders
          : this.selectedHeaders.map(id => this.allHeaders.find(header => header.value === id))

        headersToDownload = headersToDownload.map(header => `${header.value},${header.name},"${header.description}"`).join('\r\n');

        this.download(`${this.previousAnalysisName.replace(' ', '_')}_viariants_columns.csv`, fieldnames+headersToDownload)
      },
      download(filename, text) {
        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
      },
    }
  };
</script>

<style scoped lang="scss">
.iseq-report-variants-columns {
  &__content {
    display: flex;
    flex-direction: column;

    &__table {
      width: 100%;
      max-height: calc(100vh - 350px);
      overflow: scroll;
      border-right: 1px solid var(--light);

      &__selection {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 1fr 1fr;
        overflow: scroll;

        &__box {
          display: flex;
          flex-direction: column;
          width: calc(100% - 6px);
          margin: 3px;
          padding: 12px;
          background: var(--light-2);
          color: var(--dark);
          font-size: 14px;
          border-radius: 5px;
          border-width: 1px;
          border-style: solid;
          border-color: var(--light);

          &__label {
            font-weight: bold;
            color: var(--dark);
          }

          &__separator {
            margin: 4px 0;
            width: 100%;
            height: 1px;
            background-color: var(--dark);
          }

          &__description {
            display: block;
            max-width: 100%;
            overflow-wrap: break-word;
          }

          .v-input--selection-controls {
            margin-top: 0;
            padding-top: 0;
          }
        }
      }

      .pill {
        width: min-content;
        display: block;
        padding: 3px 10px;
        color: white;
        border-radius: 15px;
        font-weight: bold;

        &--inline {
          display: inline-block;
          margin-bottom: 4px;
        }
      }
    }

    &__tools {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 20px;

      &__column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: calc(50% - 50px);
        margin-bottom: 55px;
      }

      &__title {
        color: var(--dark);
        font-size: 1.7em;
        font-weight: 700;
      }

      &__button-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 10px;
        width: 100%;
      }

      &__searchbar {
        width: 100%;
        margin-top: 10px;
      }

      &__separator {
        margin-left: 15px;
        margin-right: 15px;
        width: 2px;
        height: 200px;
        background-color: var(--light);
      }
    }
  }

  &__action-button {
    margin-top: -45px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;

    &__button {
      width: 150px;
    }
  }

  .iseq-button {
    width: calc(50% - 10px);
    max-width: 200px;

    &--wide {
      width: 100%;
      max-width: 100%;
    }
  }

  .link {
    font-weight: bold;
    text-decoration: underline;
    color: var(--primary-color);
    cursor: pointer;
  }
}
</style>