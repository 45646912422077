<template>
  <div>
    <v-iseq-card
      :title="`Variant REP history - ${repTypes[type]}`"
      class="iseq-variant-rep"
    >
      <v-data-table
        :headers="tableColumns"
        :items="variantRepHistory"
        @click:row="goToVariantRep"
      >
        <template #[`item.submitter`]="{ item }">
          {{ item.submitter.email }}
        </template>
      </v-data-table>
    </v-iseq-card>
  </div>
</template>

<script>
  import IseqCard from "@/components/ui/IseqCard.vue";
  import {mapState} from "vuex";

  export default {
    name: "VariantRepHistory",
    components: {
      "v-iseq-card": IseqCard
    },
    data: function () {
      return {
        type: "",
        tableColumns: [
          {text: 'Last revision', value: 'date'},
          {text: 'Submitter', value: 'submitter'},
        ],
        repTypes: {
          SOMATIC: "Somatic variants",
          GERMLINE: "Germline variants"
        }
      }
    },
    computed: {
      ...mapState('project', ['project', 'variantRepHistory']),
    },
    watch: {
      project: {
        deep: true,
        handler() {
          this.$router.push({path: '/variant-rep'});
        }
      }
    },
    created() {
      this.type = this.$route.params.type;
      this.$store.dispatch('project/getVariantRepHistory', {projectUuid: this.project.uuid, type: this.type});
    },
    methods: {
      goToVariantRep(event) {
        this.$router.push({path: `/variant-rep/${event.uuid}`});
      },
    }
  }
</script>

<style scoped lang="scss">
.iseq-variant-rep {

}
</style>