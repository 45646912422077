var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-iseq-card',{staticClass:"iseq-variant-rep",attrs:{"title":("Variant REP - " + _vm.repname + " " + (_vm.variantRepFile.main ? '' : '(Historical version)'))}},[_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.variantRepFile.variants},scopedSlots:_vm._u([{key:"item.submitter",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.submitter.email)+" ")]}},{key:"item.primaryClassification",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.translateClassificationName(item.primaryClassification))+" ")]}},{key:"item.manualClassification",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.translateClassificationName(item.manualClassification))+" ")]}},(_vm.permissionRequired('useVariantMiner'))?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-iseq-button',{staticStyle:{"margin-right":"5px"},attrs:{"type":"secondary","small":""},on:{"click":function($event){return _vm.reclassifyVariant(item)}}},[_vm._v(" Reclassify ")]),_c('v-iseq-button',{attrs:{"type":"red","small":""},on:{"click":function($event){return _vm.removeVariant(item)}}},[_vm._v(" Remove variant ")])]}}:null],null,true)})],1),(_vm.removalModalVisibility)?_c('iseq-confirmation-modal',{attrs:{"width":"600px","header":"Remove variant reclassification","message":("Do you want to remove manual reclassification of this variant? (" + (_vm.variantToRemove.gene) + ")"),"confirm-button-type":"danger","confirm-button-text":"Remove variant","reject-button-type":"secondary","reject-button-text":"Close"},on:{"actionRejected":function($event){_vm.removalModalVisibility = false},"actionConfirmed":_vm.confirmRemoveVariant}}):_vm._e(),(_vm.reclassificationModalVisibility)?_c('iseq-variant-reclassification-modal',{attrs:{"variant":_vm.variantToReclassify,"type":_vm.variantRepFile.type},on:{"actionRejected":function($event){_vm.reclassificationModalVisibility = false},"actionConfirmed":_vm.confirmReclassifyVariant}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }